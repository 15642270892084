import React, { useEffect, useState, useMemo } from "react";

import reportService from "../services/report.service";
import RTable from "../common/components/rb-table/RTable";
import styled from "styled-components";

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  color: 
`

const Reports = () => {

  //let curuser = props.cu;

  const [data, setData] = useState([]);
  const [message, setMessage] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // Total pages from API
  const [pageIndex, setPageIndex] = useState(1); // Current page
  const [pageSize, setPageSize] = useState(10); // Number of rows per page

  //File Id, Timestamp, Corrosion Rate(mpy), SGV(m/s), pH, Flow Pattern, Annual Wall Loss(%),  Actions
  const columns = useMemo(
    () => [
      {
        Header: "File Id",
        accessor: "id"
      },
      {
        Header: "Line Id",
        accessor: "lineId"
      },
      {
        Header: "Timestamp",
        accessor: "processedTime"
      },
      {
        Header: "Corrosion Rate(mpy)",
        accessor: "corrosionRate"
      },
      {
        Header: "SGV(m/s)",
        accessor: "avgSGV"
      },
      {
        Header: "SLV(m/s)",
        accessor: "avgSLV"
      },
      {
        Header: "pH",
        accessor: "avgPH"
      },
      {
        Header: "Flow Pattern",
        accessor: "flowPattern"
      },
      {
        Header: "Annual Wall Loss(%)",
        accessor: "annualWallLoss"
      },
      {
        Header: "Actions",
        accessor: "link"
      }
    ],
    []
  );

  // Fetch data when the component loads or the pageIndex/pageSize changes
  useEffect(() => {
    setMessage(null);
    reportService.getReport(pageIndex, pageSize).then(
      (response) => {
        const data = response.data.data.enpicdaFileDataList.map((item) => {
          return {
            ...item,
            flowPattern: item.flowPattern.join(","), // Transform array to comma-separated string
            link: {
              to: "/summary/" + item.id,
              name: "View",
            },
          };
        });
        setData(data);
        setTotalPages(response.data.data.totalPages);
      },
      (error) => {
        setMessage(
          (error.response && error.response.data) ||
          error.message ||
          "No Data!"
        );
      }
    );
  }, [pageIndex, pageSize]); // Re-fetch data when pageIndex or pageSize changes

  // Function to handle page changes
  const handlePageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  // Function to handle page size changes
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageIndex(1); // Reset to the first page when page size changes
  };

  return (
    <div className="reportheader">
      <header className="jumbotron">
        <h3>Reports</h3>
     
        {message ? 
          <ErrorMessage>{message}</ErrorMessage>
          :
          <RTable
            columns={columns}
            data={data}
            totalPages={totalPages}
            pageIndex={pageIndex}
            pageSize={pageSize}
            gotoPage={handlePageChange} // Pass the page change function
            setPageSize={handlePageSizeChange} // Pass the page size change function
          />
        }
       </header>
    </div>
  );
}

export default Reports;